var questions = $('.question').length; // stores the question number of questions
var myQuestions = $('section.q-n-a');
var currQ = 0; //question we are currently showing
var pathDev = "/";
var pathProd = "/hair/";

var env = pathProd;


function checkSelectedAnswer() {
  $('.q-n-a').each(function() {
      var qid = $(this).find('.answer').data('qid');
      if ($(this).find('.answer.selected[data-qid="' + qid + '"]').length === 0) {
          $(this).find('.next').hide();
      } else {
          $(this).find('.next').show();
      }
  });
}

myQuestions.each( function(index){
    var myAnswers = $(this).find('.answer');
    $(this).find('.answers').html(myAnswers);
    $(this).attr('id', index+1);
    
})

$('#quiz-area').html(myQuestions);

//hide and show the current question
function showQ(){
  $('section.q-n-a').hide();
  currQ++;
  if( $('#'+currQ).length > 0){
      //there is at least one of that id on this page
      $('#'+currQ).fadeIn('slow');
  } else {
      $('.result').fadeIn('slow');
  }
  
}

$('#start-quiz').on('click', function() {
    $('#quiz-area').show();
    $('.home-screen').hide();
    showQ();
    checkSelectedAnswer();
  });

$('.next, .take-another-quiz').click (function (){
    showQ();
});

// Create an object to store answers for each question
var allAnswers = {};

$('.answer').on('click', function() {
  var qid = $(this).data('qid');
  var value = $(this).data('value');
  var allowMultiple = $(this).parent().data('allow-multiple');
  var maxSelections = 3; // Maximum number of selections allowed

  // Create a new array for the question if it doesn't exist
  if (!allAnswers[qid]) {
    allAnswers[qid] = [];
  }

  // Check if the maximum number of selections has been reached
  if (allAnswers[qid].length === maxSelections && !$(this).hasClass('selected')) {
    alert('You have already selected the maximum number of answers for this question.');
    $('.answer[data-qid="'+qid+'"]').attr('disabled', 'disabled');
    return;
  }

  if (allowMultiple) {
    if ($(this).hasClass('selected')) {
      $(this).removeClass('selected');
      var index = allAnswers[qid].indexOf(value);
      if (index !== -1) {
        allAnswers[qid].splice(index, 1);
      }
    } else {
      $(this).addClass('selected');
      allAnswers[qid].push(value);
    }
  } else {
    if ($(this).parent().find('.selected').length > 0) {
      $(this).parent().find('.selected').removeClass('selected');
    }
    $(this).addClass('selected');
    allAnswers[qid] = [value];
  }
  checkSelectedAnswer();
});


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

// $('#result').on('click', function(){
//   var resultHtml = "";
//   var resultMessageHtml = "";

//   var allQuestionsAnswered = true;
//   $('.answers').each(function(index, element){
//     if($(element).find('.answer.selected').length === 0){
//       allQuestionsAnswered = false;
//       return false; // exit loop early if any question is unanswered
//     }
//   });
  
//   //check if all questions are answered
//   if(allQuestionsAnswered){
//       fetch("hair-data.json")
//       .then(response => response.json())
//       .then(data => {
//           // filter the data based on user answers
//           let filteredData = data.filter(item => {
//             const q1Matches = item.q1.some(answer => allAnswers.q1.includes(answer));

//             const q2Matches = item.q2.some(answer => allAnswers.q2.includes(answer));
//             const q3Matches = item.q3.some(answer => allAnswers.q3.includes(answer));

//             return (q1Matches && q2Matches) || (q1Matches && q3Matches);
//           });

//           // sort filtered data by priority in ascending order
//           filteredData.sort((a, b) => a.priority - b.priority);

//           // limit to maximum of 5 products
//           filteredData = filteredData.slice(0, 5);

          
//           if (filteredData.length > 0) {
//               resultMessageHtml += '<p class = "main-p">Thank you for completing the quiz!</p>';
//               // resultMessageHtml += '<p class = "sub-p">Here are the products that might interest you.</p>';
//               // resultMessageHtml += '<p class = "sub-p-2">Here\'s an exclusive ₱150 off ₱799 code for you:'; 
//               // resultMessageHtml += '</br><span>" OLAYSBDLZD0523 "</span>';
//               // resultMessageHtml += '</br>*only available on May 23</p>';

//               resultHtml += '<ul class = "result-area-ul">';
//               for (let i = 0; i < filteredData.length; i++) {
//                   resultHtml +=
//                   '<li class = "result-area-li">' +
//                     '<div class = "product-image">' +
//                       '<img src ="'+env+'assets/img/'+ filteredData[i].image +'">' +
//                     '</div>' +
//                     '<div class = "product-content">'+
//                         '<h3>'+ filteredData[i].title +'</h3>'+
//                         '<a target = "_blank" href = "'+ filteredData[i].shopLink +'"><button class = "product-button" >Shop Now!</button></a>'+
//                     '</div>'+
//                   '</li>';
//               }
//               resultHtml += '</ul>';
//               var resultArea = document.getElementById('result-area-id');
//               resultArea.innerHTML = resultHtml;

//               var resultMessage = document.getElementById('result-area-message-id');
//               resultMessage.innerHTML = resultMessageHtml;

//           } else {
//               // if there are no matching answers, display top priority products
//               let topPriorityData = data.filter(item => item.priority === 1);
//               shuffleArray(topPriorityData);
//               topPriorityData = topPriorityData.slice(0, 5);
//               resultMessageHtml += '<p class = "main-p">We think you\'ll love these recommendations!</p>';
//               // resultMessageHtml += '<p class = "sub-p-2">Here\'s an exclusive ₱150 off ₱799 code for you:'; 
//               // resultMessageHtml += '</br><span>" OLAYSBDLZD0523 "</span>';
//               // resultMessageHtml += '</br>*only available on May 23</p>';
//               resultHtml += '<ul class = "result-area-ul">';
//               for (let i = 0; i < topPriorityData.length; i++) {
//                   resultHtml +=
//                   '<li class = "result-area-li">' +
//                     '<div class = "product-image">' +
//                       '<img src ="'+env+'assets/img/'+ topPriorityData[i].image +'">' +
//                     '</div>' +
//                     '<div class = "product-content">'+
//                         '<h3>'+ topPriorityData[i].title +'</h3>'+
//                         '<a target = "_blank" href = "'+ topPriorityData[i].shopLink +'"><button class = "product-button" >Shop Now!</button></a>'+
//                     '</div>'+
//                   '</li>';
//               }
//               resultHtml += '</ul>';
//               var resultMessage = document.getElementById('result-area-message-id');
//               resultMessage.innerHTML = resultMessageHtml;
              
//           }

//           var resultArea = document.getElementById('result-area-id');
//           resultArea.innerHTML = resultHtml;
//       });
      
      
//       $('#quiz-area').hide();
//       $('.results-button, .result-area, .result-area-message').show();
//   }

// });


// // Get the total number of questions
// var totalQuestions = $('.q-n-a').length;

// // Add a progress bar to each question section
// $('.q-n-a').each(function(index) {
//   // Calculate the progress percentage
//   var progress = Math.round(((index + 1) / totalQuestions) * 100);

//   // Create the progress bar HTML
//   var progressBar = '<div class="progress-bar">';
//   progressBar += '<div class="progress" style="width: ' + progress + '%;"></div>';
//   progressBar += '</div>';

//   // Insert the progress bar into the question section
//   $(this).prepend(progressBar);
// });



// $('.take-another-quiz').on('click', function() {
//   currQ = 0;
//   allAnswers = {};
//   $('.selected').removeClass('selected');
//   $('#quiz-area, .results-button, .result-area, .result-area-message').hide();
//   $('.home-screen').show();
// });


    // This code assumes you are using jQuery for convenience
    $(document).ready(function() {
      let q1Answer = "";
      let q2Answer = "";

      $('.answer').on('click', function() {
          const qid = $(this).data('qid');
          const value = $(this).data('value');
          
          if (qid === "q1") {
              q1Answer = value;
          } else if (qid === "q2") {
              q2Answer = value;
          }
          
          // Add visual feedback for the selected answer
      });

      $('#result').on('click', function() {
          if (q2Answer !== "") {
              const newURL = `/${q2Answer}`; // Modify the URL structure as needed
              window.location.href = newURL;
          } else {
              // Handle the case when q2 answer is not selected
          }
      });
  });



